export default [
  {
    path: "/pool",
    name: "pool",
    redirect: "/pool/hot",
    children: [
      {
        path: "/pool/create",
        name: "pool_create",
        component: () => import("@/views/pool/create.vue"),
      },
      {
        path: "/pool/edit/:id",
        name: "pool_edit",
        component: () => import("@/views/pool/edit.vue"),
      },
      {
        path: "/pool/hot",
        name: "pool_hot",
        component: () => import("@/views/pool/hot.vue"),
      },
      {
        path: '/pool/detail/:id',
        name: 'pool_detail',
        component: () => import('@/views/pool/detail.vue')
      },
      {
        path: '/pool/request/:id',
        name: 'pool_request',
        component: () => import('@/views/pool/request.vue')
      },
      {
        path: '/pool/invite',
        name: 'pool_invite',
        component: () => import('@/views/pool/invite.vue')
      },
      {
        path: '/pool/handle',
        name: 'pool_handle',
        component: () => import('@/views/pool/handle.vue')
      },
      {
        path: '/pool/like',
        name: 'pool_like',
        component: () => import('@/views/pool/like.vue')
      },
      {
        path: "/pool/leader/:state",
        name: "pool_leader",
        component: () => import('@/views/pool/leader.vue')
      },
      {
        path: "/pool/member/:state",
        name: "pool_member",
        component: () => import('@/views/pool/member.vue')
      }
    ],
  },
];
